// TODO: use .env files to achieve this. More info: https://create-react-app.dev/docs/adding-custom-environment-variables/

const defConfig = {
    api: {
        url: 'http://localhost:9310'
    },
    socketIO: {
        baseUrl: 'https://api.platta.cloud'
    },
    appInsights: {
        instrumentationKey: 'fef4001c-30c1-4420-b65f-420fc18219bf'
    },
    google: {
        country: 'fi'
    },
    app: {
        name: 'booker-ui',
    }
};

const envConfig = {
    'dev': {
        api: {
            url: 'https://api.platta.cloud/dev/booker/v1',
        },
        socketIO: {
            path: '/dev/booker/v1/socket.io'
        },
        appInsights: {
            instrumentationKey: 'fef4001c-30c1-4420-b65f-420fc18219bf'
        }
    },
    'test': {
        api: {
            url: 'https://api.platta.cloud/test/booker/v1',
        },
        socketIO: {
            path: '/test/booker/v1/socket.io'
        },
        appInsights: {
            instrumentationKey: 'feb6aa9c-b4db-43f3-a93e-295e2c7a036f'
        }
    },
    'prod': {
        api: {
            url: 'https://api.platta.cloud/booker/v1',
        },
        socketIO: {
            path: '/booker/v1/socket.io'
        },
        appInsights: {
            instrumentationKey: '4392c683-d8b9-4ecd-b2de-323b1bb19a34'
        }
    }
};

class AppConfig {
    config: any;

    constructor() {
        // first apply default config (use its copy!)
        this.config = JSON.parse(JSON.stringify(defConfig));

        // now check if there are any overrides available for the environments. If so - apply them
        const env = "test";
        if (env && envConfig.hasOwnProperty(env)) {
            AppConfig.deepMerge(this.config, envConfig[env]);
        }
    }

    // merge recursively
    static deepMerge(target, source) {
        Object.keys(source) // start iterating through the own keys of the object
            .forEach(k => source[k] instanceof Object
                ? AppConfig.deepMerge((target[k] || (target[k] = {})), source[k])
                : target[k] = source[k]
            );
    }

    static get env(): string {
        const [app, env]: string[] = window.location.hostname.split('.');

        return env || app;
    }
}

const appConfig = new AppConfig();

export default appConfig.config;
